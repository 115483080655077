import { DashboardWrapper } from './DashboardStyle';
import Header from 'components/header/Header';
import { SectionHeader, SectionWrapper } from 'styles/reusable/SectionStyle';
import Activity from 'components/userInformation/activity/Activity';
import History from 'components/userInformation/history/History';
import Location from 'components/userInformation/location/Location';
import TechnicalSpecs from 'components/userInformation/technicalSpecs/TechnicalSpecs';
import VisitorData from 'components/userInformation/visitorData/VisitorData';
import Video from 'components/userInformation/video/Video';
import InteractionInfo from 'components/userInformation/interactionInfo/InteractionInfo';
import UserInput from 'components/userInformation/userInput/UserInput';

const Dashboard = () => {
  return (
    <DashboardWrapper>
      <Header />
      <div id="te_visitor_chat_video_wrapper" style={{ marginTop: '32px' }}>
        <InteractionInfo />
      </div>
      <SectionWrapper id="te_visitor_video_wrapper">
        <SectionHeader>Video</SectionHeader>
        <Video />
      </SectionWrapper>
      <SectionWrapper id="te_visitor_form_wrapper">
        <UserInput />
      </SectionWrapper>
      <SectionWrapper id="te_visitor_data_wrapper">
        <SectionHeader>Visitor data</SectionHeader>
        <VisitorData />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeader>Users current activity</SectionHeader>
        <Activity />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeader>User History</SectionHeader>
        <History />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeader>User Information</SectionHeader>
        <Location />
        <TechnicalSpecs />
      </SectionWrapper>
    </DashboardWrapper>
  );
};

export default Dashboard;
