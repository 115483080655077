import { ReactComponent as PanelIcon } from 'assets/icons/formIcon.svg';
import Panel from 'shared/components/panel/Panel';

const UserInput = () => {
    return (
        <Panel
            Icon={PanelIcon}
            headerText={'Pre-Interaction Form'}
            content="UserInputComponent"
            isInitiallyOpen={true}
        />
    );
};

export default UserInput;